import axios from 'axios';

const baseURL = process.env.GATSBY_STRAPI_FORM_URL;
const token = process.env.GATSBY_STRAPI_USER_REG_TOKEN;

// Agent API
export const Building_API = "/property-uploader/api/pu-building/";
export const Property_API = "/property-uploader/api/pu-property/";
export const Region_API = "/property-uploader/api/pu-region/";

const authInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const GetAPIData = async (data) => {

    const res = await authInstance.get(data)
    .then((res) => {
      // Success
      if (res.statusText === 'OK') {
        return {
          success: true,
          ...res.data,
        }
      }
      return { success: false }
    })
    .catch((error) => {
      // Failed
      if (error.response) {
        return {
          success: false,
          message: error.response.data,
        }
      } else {
        // Service error
      }
    })
  
    return res;
  
}

export const GetRegionAPI = async () => {

  const res = await authInstance.get(Region_API)
  .then((res) => {
    // Success
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })

  return res;
}